import {
  DYNAMIC_AUDIT_SHEET_KEY,
  addionalAttributesKeys,
  auditedRuvColumns,
  defaultColumns,
  defaultDataProps,
  firstSetColumnKeyOrder,
  rvuColumnsOrder,
} from '../constants/constants';
import {
  AUDIT_COLUMN_HEADER,
  columnType,
  columnWidth,
  dynamicAuditColumnHeader,
  dynamicColumnRenderes,
} from '../pages/audit_sheet/constants';
import { toTitleCase } from './common.utils';
import { format } from 'date-fns';

/**
 *
 * @param {Array} data
 * @returns { data with adtional_attribues }
 */

function haveAddionalAttributes(data) {
  const noAddionalAttributes = data.every(
    obj => obj.additional_attributes === null
  );

  if (noAddionalAttributes) {
    return data.map(d => {
      const tempObj = {
        ...d,
        additional_attributes: {
          provider_education: null,
          number_complexity: null,
          amount_complexity: null,
          risk_complications: null,
          audited_icd: null,
          audited_icd: null,
          icd_agree: null,
          icd_disagree: null,
        },
      };
      //  delete d.old_rendering

      return tempObj;
    });
  } else {
    return data;
  }
}

function cptAndIcdColumnValidator(value, callback) {
  if (/^\d+(\.\d{1,2})?$/.test(value)) {
    callback(true);
  } else {
    callback(false);
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with srvcs Column }
 */

function isHaveSrvcsNoColumn(response) {
  const isEmptySrvcsColumn = response.every(obj => obj?.srvcs_no === null);

  if (isEmptySrvcsColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('srvcs_no_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            srvcs_no_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with billedCPT column }
 */

function isHaveBilledICDColumn(response) {
  const havebilledICD = response
    .map(obj => {
      return obj.additional_attributes.hasOwnProperty('billed_icd');
    })
    .every(Boolean);

  const isEmptyBilledCptColumn = response.every(
    obj => obj?.additional_attributes?.billed_icd === null
  );

  if (!havebilledICD) {
    return response.map(res => {
      return {
        ...res,
        additional_attributes: {
          billed_icd: res.additional_attributes?.billed_icd
            ? res.additional_attributes?.billed_icd
            : null,
          ...res.additional_attributes,
        },
      };
    });
  } else if (isEmptyBilledCptColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res?.additional_attributes?.hasOwnProperty('billed_icd_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            billed_icd_2: res.additional_attributes?.billed_icd_2
              ? res.additional_attributes?.billed_icd_2
              : null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with billedCPTColumn }
 */

function isHaveBilledModifierColumn(response) {
  const isEmptyBilledModifierColumn = response.every(
    obj => obj?.billed_modifier === null
  );

  if (isEmptyBilledModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('billed_modifier_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            billed_modifier_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns
 */
function isHAveAuditedCodeColumn(response) {
  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.audited_code === null
  );

  if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_cpt_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            audited_cpt_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with auditedcptColumn }
 */
function isHaveAuditedCPTColumn(response) {
  const haveAuditedCpt = response
    .map(obj => {
      return obj.additional_attributes.hasOwnProperty('audited_icd');
    })
    .every(Boolean);

  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.additional_attributes?.audited_icd === null
  );

  if (!haveAuditedCpt) {
    return response.map(res => {
      return {
        ...res,
        additional_attributes: {
          audited_icd: res.additional_attributes?.audited_icd
            ? res.additional_attributes?.audited_icd
            : null,
          ...res.additional_attributes,
        },
      };
    });
  } else if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_icd_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            audited_icd_2: res.additional_attributes?.audited_icd_2
              ? res.additional_attributes?.audited_icd_2
              : null,
            ...res?.additional_attributes,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with auditedModifier }
 */
function isHaveAuditedModifierColumn(response) {
  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.audited_modifier === null
  );

  if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_modifier_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            audited_modifier_2: null,
          },
        };
      }
    });
  }
}

export function createEmptyColumn(response) {
  const _haveAddionalAttributes = haveAddionalAttributes(response);

  // billed
  const _isHaveSrvcsNoColumn = isHaveSrvcsNoColumn(_haveAddionalAttributes);

  const _isHaveBilledCPTColumn = isHaveBilledICDColumn(_isHaveSrvcsNoColumn);

  const _isHaveBilledModifierColumn = isHaveBilledModifierColumn(
    _isHaveBilledCPTColumn
  );

  // audited
  const _isHAveAuditedCodeColumn = isHAveAuditedCodeColumn(
    _isHaveBilledModifierColumn
  );

  const _isHaveAuditedCPTColumn = isHaveAuditedCPTColumn(
    _isHAveAuditedCodeColumn
  );

  const _isHaveAuditedModifierColumn = isHaveAuditedModifierColumn(
    _isHaveAuditedCPTColumn
  );

  return _isHaveAuditedModifierColumn;
}

// prepare auditsheet headers

/**
 *
 * @param {Array} columns
 */

function sortColumns(columns) {
  return columns.sort((a, b) => {
    const aNum = parseInt(a.match(/\d+/));
    const bNum = parseInt(b.match(/\d+/));

    if (isNaN(aNum) && isNaN(bNum)) {
      return 0;
    } else if (isNaN(aNum)) {
      return -1;
    } else if (isNaN(bNum)) {
      return 1;
    }

    return aNum - bNum;
  });
}

/**
 *
 * @param {Array} headers
 */

function arrangeHeader(headers) {
  const firstSetColumns = ['M.Info', 'Encounter #', 'Rendering', 'Enc Dt'];

  const billedIcdColumns = [];

  const billedCptColumns = [];

  const billedModifierColumns = [];

  const agreeColumns = ['RVU', '$ value', 'Response', 'CPT Agree', 'CPT Disagree', 'ICD Agree', 'ICD Disagree'];

  const auditedIcdColumns = [];

  const auditedCptColumns = [];

  const auditedModifier = [];

  const auditedRvuValues = ['Audited RVU', 'Audited $ Value', 'Notes'];

  const lastSetColumns = [
    'Provider Education',
    'Number & Complexity',
    'Amount & Complexity',
    'Mortality of PatientManagement',
  ];

  headers.forEach(h => {
    if (h) {
      if (h.match('Billed ICD')) {
        billedIcdColumns.push(h);
      } else if (h.match('Billed CPT')) {
        billedCptColumns.push(h);
      } else if (h.match('Billed Modifier')) {
        const header = h.replace(/^Billed\s+/i, '');
        billedModifierColumns.push(header);
      } else if (h.match('Audited ICD')) {
        auditedIcdColumns.push(h);
      } else if (h.match('Audited CPT')) {
        auditedCptColumns.push(h);
      } else if (h.match('Audited Modifier')) {
        const header = h.replace(/^Audited\s+/i, '');
        auditedModifier.push(h);
      }
    }
  });

  const billedColumns = [
    ...sortColumns(billedIcdColumns),
    ...sortColumns(billedCptColumns),
    ...sortColumns(billedModifierColumns),
  ];
  const auditedColumns = [
    ...sortColumns(auditedIcdColumns),
    ...sortColumns(auditedCptColumns),
    ...sortColumns(auditedModifier),
  ];

  const sortedHeaders = [
    ...firstSetColumns,
    ...billedColumns,
    ...agreeColumns,
    ...auditedColumns,
    ...auditedRvuValues,
    ...lastSetColumns,
  ];

  return sortedHeaders;
}

/**
 *
 * @param {String} key
 * @returns
 */
function prepareKey(key) {
  if (key.match('srvcs_no')) {
    const k = key.replace(/srvcs_no/i, 'billed_cpt');

    const columnKey = k.replace(/_/g, ' ').split(' ');

    const replaceStr = columnKey.map((w, i) => {
      if (i === 0) {
        return toTitleCase(w);
      } else {
        return w.toUpperCase();
      }
    });

    return replaceStr.join(' ');
  }

  const columnKey = key.replace(/_/g, ' ').split(' ');

  const replaceStr = columnKey.map((w, i) => {
    if (i === 0) {
      return toTitleCase(w);
    } else if (w === 'icd' || w === 'cpt') {
      return w.toUpperCase();
    } else {
      return toTitleCase(w);
    }
  });

  return replaceStr.join(' ');

  /** 
  if (key.match('audited_icd')) {
    const columnKey = key.split('_');
    const replaceStr = columnKey.map((w, i) => {
      if (i === 0) {
        return toTitleCase(w);
      } else {
        return w.toUpperCase();
      }
    });

    return replaceStr.join(' ');
  }

  if (key.match('billed_cpt')) {
    const columnKey = key.split('_');
    const replaceStr = columnKey.map((w, i) => {
      if (i === 0) {
        return toTitleCase(w);
      } else {
        return w.toUpperCase();
      }
    });

    return replaceStr.join(' ');
  }

  const unKnownKey = key.split('_');

  const _unKnownKey = unKnownKey.map(uk => {
    if(["icd",'cpt'].includes(uk)){
      return uk.toUpperCase()
    }else{
      return  toTitleCase(uk)
    }
  });


  if (_unKnownKey) {
    return _unKnownKey.join(" ");
  }
  */
}

/**
 *
 * @param {Array} sheetData
 *
 */

export function prepareAuditSheetHeader(sheetData) {
  const headers = [];

  [sheetData[0]].forEach(sd => {
    if (sd && Object.keys(sd)) {
      Object?.keys(sd).forEach(key => {
        if (
          key !== 'file_name' &&
          key !== 'id' &&
          key !== 'row_id' &&
          key !== 'chart_id' &&
          key !== 'old_rendering'
        ) {
          if (dynamicAuditColumnHeader[key]) {
            headers.push(dynamicAuditColumnHeader[key]);
          } else {
            const newKey = prepareKey(key);
            headers.push(newKey);
          }
        }
      });
    }
  });

  return arrangeHeader(headers);

  // const _headers = arrangeHeader(headers);
  // return Array.from(new Set(_headers));
}

// agree and disAgree  column validator functions
function agreeValidator(value, callback) {
  setTimeout(() => {
    if (value.toLowerCase() === 'yes' || value === '-') {
      callback(true);
    } else {
      callback(false);
    }
  }, 1000);
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareSrvcColumn(key, windowSize, view) {
  return {
    data: key,
    type: 'text',
    width: windowSize * 0.15,
    readOnly: view,
    renderer: dynamicColumnRenderes.srvcs_no,
  };
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareBilledColumn(key, windowSize, view) {
  if (key.match('billed_cpt')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: dynamicColumnRenderes.srvcs_no,
    };
  } else if (key.match('billed_modifier')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('billed_icd')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: dynamicColumnRenderes.billed_icd,
      validator: cptAndIcdColumnValidator,
      numericFormat: {
        pattern: '0.00',
      },
    };
  } else {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  }
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareAuditedColumn(key, windowSize, view) {
  if (key.match('audited_icd')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: dynamicColumnRenderes.billed_icd,
      validator: cptAndIcdColumnValidator,
      numericFormat: {
        pattern: '0.00',
      },
    };
  } else if (key.match('audited_cpt')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('audited_modifier')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  }
}

function sortData(obj) {
  return Object.keys(obj)
    .sort((a, b) => {
      const hasNumberA = /\d+$/.test(a);
      const hasNumberB = /\d+$/.test(b);

      if (hasNumberA && hasNumberB) {
        const numberA = parseInt(a.match(/\d+$/)[0]);
        const numberB = parseInt(b.match(/\d+$/)[0]);
        return numberA - numberB;
      } else if (hasNumberA) {
        return 1;
      } else if (hasNumberB) {
        return -1;
      } else {
        return a.localeCompare(b);
      }
    })
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

// arragnge columns
function arrangeColumns(columnsData) {
  const firstSetColumns = [];

  const billedIctColumns = [];
  const billedCptColumns = [];
  const billedModifierColumns = [];

  const providerRvuColumns = [];

  const auditedIctColumns = [];
  const auditedCptColumns = [];
  const auditedModifierColumns = [];

  const _auditedRuvColumns = [];
  const _defaultColumns = [];

  let output = [];

  columnsData.forEach(cd => {
    if (firstSetColumnKeyOrder.includes(cd?.data)) {
      firstSetColumns.push(cd);
    } else if (rvuColumnsOrder.includes(cd?.data)) {
      providerRvuColumns.push(cd);
    } else if (auditedRuvColumns.includes(cd?.data)) {
      _auditedRuvColumns.push(cd);
    } else if (defaultColumns.includes(cd?.data)) {
      _defaultColumns.push(cd);
    } else if (cd?.data.match('srvcs_no')) {
      billedCptColumns.push(cd);
    } else if (cd?.data.match('billed_icd')) {
      billedIctColumns.push(cd);
    } else if (cd?.data.match('billed_modifier')) {
      billedModifierColumns.push(cd);
    } else if (cd?.data.match('audited_icd')) {
      auditedIctColumns.push(cd);
    } else if (cd?.data.match('audited_cpt')) {
      auditedCptColumns.push(cd);
    } else if (cd?.data.match('audited_modifier')) {
      auditedModifierColumns.push(cd);
    }
  });

  const ictCol = [...Object.values(sortData(billedIctColumns))];
  const cptCol = [...Object.values(sortData(billedCptColumns))];
  const modCol = [...Object.values(sortData(billedModifierColumns))];

  const aIctCol = [...Object.values(sortData(auditedIctColumns))];
  const aCptCol = [...Object.values(sortData(auditedCptColumns))];
  const aModCol = [...Object.values(sortData(auditedModifierColumns))];

  const tempObj = _defaultColumns.sort((a, b) => {
    const indexA = defaultColumns.indexOf(a.data);
    const indexB = defaultColumns.indexOf(b.data);
    return indexA - indexB;
  });

  const col = [
    ...firstSetColumns,
    ...ictCol,
    ...cptCol,
    ...modCol,
    ...providerRvuColumns,

    ...aIctCol,
    ...aCptCol,
    ...aModCol,
    ..._auditedRuvColumns,
    ...tempObj,
  ];

  output.push(col);

  return output.flat();
}

// sheet column
export function prepareAuditSheetColumn(
  _sheetData,
  windowSize,
  view,
  providerOptions,
  isAuditedDollarDisabled,
  isProviderDollarDisabled
) {
  const sheetData = [_sheetData[0]];

  const auditSheetColumns = [];
  sheetData.forEach(sd => {
    let columnProps = {};

    if (sd && Object.keys(sd).length) {
      Object?.keys(sd).forEach(key => {
        if (dynamicAuditColumnHeader[key]) {
          columnProps['data'] = key;
          columnProps['type'] = columnType[key];
          columnProps['width'] = windowSize * columnWidth[key];
          columnProps['readOnly'] = view;
          columnProps['renderer'] = dynamicColumnRenderes[key];

          if (key === AUDIT_COLUMN_HEADER.RENDERING) {
            const providerNames = providerOptions.map(p => p.value);
            columnProps['source'] = providerNames;
          }

          if (key === AUDIT_COLUMN_HEADER.ENC_DT) {
            columnProps['dateFormat'] = 'MM-DD-YYYY';
            columnProps['datePickerConfig'] = {
              disableDayFn(date) {
                return date > new Date();
              },
            };
          }

          if (key === AUDIT_COLUMN_HEADER.PROVIDER_DOLLER_VALUE) {
            columnProps['readOnly'] = view || isProviderDollarDisabled;
          }

          if (key === AUDIT_COLUMN_HEADER.AUDITOR_DOLLER_VALUE) {
            columnProps['readOnly'] = view || isAuditedDollarDisabled;
          }

          if (
            key === AUDIT_COLUMN_HEADER.AGREE ||
            key === AUDIT_COLUMN_HEADER.DISAGREE ||
            key === AUDIT_COLUMN_HEADER.ICD_AGREE ||
            key === AUDIT_COLUMN_HEADER.ICD_DISAGREE
          ) {
            columnProps['validator'] = agreeValidator;
            columnProps['renderer'] = 'agreeRenderer';
          }

          if (key === AUDIT_COLUMN_HEADER.BILLED_ICD) {
            columnProps['validator'] = cptAndIcdColumnValidator;
            columnProps['numericFormat'] = {
              pattern: '0.00',
            };
          }

          if (key === AUDIT_COLUMN_HEADER.AUDITED_ICD) {
            columnProps['validator'] = cptAndIcdColumnValidator;
            columnProps['numericFormat'] = {
              pattern: '0.00',
            };
          }

          if (key === AUDIT_COLUMN_HEADER.MISSING_INFO) {
            // columnProps['type'] = 'checkbox';
            columnProps['checkedTemplate'] = true;
            columnProps['uncheckedTemplate'] = null;
            columnProps['readOnly'] = view;
          }

          auditSheetColumns.push(columnProps);
          columnProps = {};
        } else if (key?.match('srvcs_no')) {
          const srvcColumnProps = prepareSrvcColumn(key, windowSize, view);
          auditSheetColumns.push(srvcColumnProps);
        } else if (key?.match('billed')) {
          const billedColumnProps = prepareBilledColumn(key, windowSize, view);
          auditSheetColumns.push(billedColumnProps);
        } else if (key?.match('audited')) {
          const auditedColumnProps = prepareAuditedColumn(
            key,
            windowSize,
            view
          );
          auditSheetColumns.push(auditedColumnProps);
        }
      });
    }
  });

  const arrangedColumns = arrangeColumns(auditSheetColumns);

  return arrangedColumns;
}

// arrange data

export function arrangeData(sheetData) {
  let firstSetDatas = {};

  let billedIcddatas = {};
  let billedCptDatas = {};
  let billedModifierDatas = {};

  let agreeColumns = {};

  let auditedIcdData = {};
  let auditedCptData = {};
  let auditedModifierData = {};

  let auditedRvuColumns = {};

  let lastSetColumns = {};

  let defaultprops = {};

  const finalData = [];

  sheetData.forEach(item => {
    for (const key in item) {
      if (firstSetColumnKeyOrder.includes(key)) {
        Object.assign(firstSetDatas, { [key]: item[key] });
      } else if (rvuColumnsOrder.includes(key)) {
        Object.assign(agreeColumns, { [key]: item[key] });
      } else if (auditedRuvColumns.includes(key)) {
        Object.assign(auditedRvuColumns, { [key]: item[key] });
      } else if (defaultColumns.includes(key)) {
        Object.assign(lastSetColumns, { [key]: item[key] });
      } else if (key.match('srvcs_no')) {
        Object.assign(billedCptDatas, { [key]: item[key] });
      } else if (key.match('billed_icd')) {
        Object.assign(billedIcddatas, { [key]: item[key] });
      } else if (key.match('billed_modifier')) {
        Object.assign(billedModifierDatas, { [key]: item[key] });
      } else if (key.match('audited_icd')) {
        Object.assign(auditedIcdData, { [key]: item[key] });
      } else if (key.match('audited_cpt')) {
        Object.assign(auditedCptData, { [key]: item[key] });
      } else if (key.match('audited_modifier')) {
        Object.assign(auditedModifierData, { [key]: item[key] });
      } else if (defaultDataProps.includes(key)) {
        Object.assign(defaultprops, { [key]: item[key] });
      }
    }

    const billedColumns = {
      ...sortData(billedIcddatas),
      ...sortData(billedCptDatas),
      ...sortData(billedModifierDatas),
    };

    const auditedColumns = {
      ...sortData(auditedIcdData),
      ...sortData(auditedCptData),
      ...sortData(auditedModifierData),
    };

    const col = {
      ...firstSetDatas,
      ...billedColumns,
      ...agreeColumns,
      ...auditedColumns,
      ...auditedRvuColumns,
      ...lastSetColumns,
      ...defaultprops,
    };

    Object.assign(firstSetDatas, {});
    Object.assign(agreeColumns, {});
    Object.assign(auditedRvuColumns, {});
    Object.assign(lastSetColumns, {});

    Object.assign(billedIcddatas, {});
    Object.assign(billedCptDatas, {});
    Object.assign(billedModifierDatas, {});

    Object.assign(auditedIcdData, {});
    Object.assign(auditedCptData, {});
    Object.assign(auditedModifierData, {});
    Object.assign(defaultprops, {});

    finalData.push(col);
  });

  return finalData;
}

/**
 *
 * @param {Array} column
 */

export function findHighlightedColumn(column) {
  const highlightedIndices = [];

  column?.forEach(({ data }, i) => {
    if (data === 'provider_rvu') {
      highlightedIndices.push(i);
    } else if (data === 'provider_dollar_value') {
      highlightedIndices.push(i);
    } else if (data === 'audited_rvu') {
      highlightedIndices.push(i);
    } else if (data === 'audited_dollar_value') {
      highlightedIndices.push(i);
    }
  });

  return highlightedIndices;
}

export function findDollorColumn(column) {
  const dolloarColumnIndices = [];

  column.forEach(({ data }, i) => {
    if (data === 'provider_dollar_value') {
      dolloarColumnIndices.push(i);
    } else if (data === 'audited_dollar_value') {
      dolloarColumnIndices.push(i);
    }
  });

  return dolloarColumnIndices;
}

export function getAuditSheetKeys(columnData) {
  return columnData.map(({ data }, i) => {
    return data;
  });
}

export function findAgreeDisAgreeColumn(columnData) {
  const agreeDisAgreeIndices = [];
  columnData.forEach(({ data }, i) => {
    if (data === 'agree') {
      agreeDisAgreeIndices.push(i);
    } else if (data === 'disagree') {
      agreeDisAgreeIndices.push(i);
    }
  });

  return agreeDisAgreeIndices;
}

function sanitizeObject(payload) {
  for (const key of addionalAttributesKeys) {
    for (const k in payload) {
      if (k.match(key)) {
        delete payload[k];
      }
    }
  }

  return payload;
}

export function payloadreStructure(payload) {
  const arr = [];
  const payloadprops = payload[0];

  payload
    .map(({ data }) => data)
    .forEach(item => {
      item.forEach(i => {
        const additional_attributes = {};
        Object.keys(i).forEach(k => {
          addionalAttributesKeys.forEach(aak => {
            if (k.startsWith(aak)) {
              Object.assign(additional_attributes, { [k]: i[k] });
            }
          });
        });

        const _payload = sanitizeObject({ ...i, additional_attributes });

        const setAuditedCode = {
          ..._payload,
          audited_code: _payload['audited_icd'],
        };
        delete setAuditedCode['audited_icd'];

        arr.push(setAuditedCode);
      });
    });

  return [{ ...payloadprops, data: arr }];
}

export function creatColumnHeader(columnName) {
  const regex = /^(.*?)(\s+([0-9]+))?$/;
  const match = columnName?.match(regex);
  const prefix = match[1] || '';
  const number = match[3] ? parseInt(match[3]) : null;

  const prefixWithSpaces = prefix.trim();

  const incrementedNumber = number !== null ? number + 1 : 2;

  return `${prefixWithSpaces} ${incrementedNumber}`;
}

export function createColumnDataName(columnName) {
  const regex = /^(.*?)(_([0-9]+))?$/;
  const match = columnName.match(regex);
  const prefix = match[1] || '';
  const number = match[3] ? parseInt(match[3]) : 0;
  const incrementedNumber = number === 0 ? 2 : number + 1;

  const colName = `${prefix}_${incrementedNumber}`;

  return colName;
}

export function isColumnMatched(string, keywords) {
  for (let i = 0; i < keywords.length; i++) {
    const pattern = new RegExp(keywords[i] + '(_\\d+)?');
    const match = string.match(pattern);
    if (match) {
      return true;
    }
  }
  return false;
}

/**
 *
 * @param {Array} columndata
 */

export function findNumericColumns(columndata) {
  const numericColumns = [
  ];

  const indices = columndata.reduce((acc, cd, i) => {
    if (numericColumns.includes(cd.data)) {
      acc.push(i);
    }
    return acc;
  }, []);

  return indices;
}

export const createEmptyTablesProps = currentUpload => {
  return [
    {
      index: 1,
      sheet_name: 'Sheet1',
      data: [
        {
          file_name: currentUpload?.upload_id,
          chart_id: currentUpload.id,
          row_id: 1,

          id: null,

          missing_info: null,
          encounter_no: null,
          rendering: null, //{ first_name: '', last_name: '', id: '' },
          enc_dt: null,

          additional_attributes: {
            billed_icd: null,

            audited_icd: null,

            provider_education: null,
            number_complexity: null,
            amount_complexity: null,
            risk_complications: null,
          },

          srvcs_no: null,
          billed_modifier: null,

          provider_rvu: null,
          provider_dollar_value: null,
          response: null,
          agree: null,
          disagree: null,

          audited_code: null,
          audited_modifier: null,

          audited_rvu: null,
          audited_dollar_value: null,
          notes: null,
        },
      ],
    },
  ];
};

// export function prepareUpdatePayload(payload) {
//   return payload.map(sheet => {
//     return {
//       ...sheet,
//       data: sheet.data.map(entry => {
//         const updatedEntry = { ...entry };
//         const additionalAttributes = {};

//         for (const key in updatedEntry) {
//           if (!DYNAMIC_AUDIT_SHEET_KEY.includes(key)) {
//             additionalAttributes[key] = updatedEntry[key];
//             delete updatedEntry[key];
//           } else if (key === 'audited_cpt') {
//             updatedEntry.audited_code = updatedEntry.audited_cpt;
//             delete updatedEntry.audited_cpt;
//           }
//         }

//         if (Object.keys(additionalAttributes).length > 0) {
//           updatedEntry.additional_attributes = additionalAttributes;
//         }

//         return updatedEntry;
//       }),
//     };
//   });
// }

export function prepareUpdatePayload(payload) {
  return payload.map(sheet => {
    const payloadData = sheet?.data?.map(entry => {
      const updatedEntry = { ...entry };
      const additionalAttributes = {};

      for (const key in updatedEntry) {
        if (!DYNAMIC_AUDIT_SHEET_KEY.includes(key)) {
          additionalAttributes[key] = updatedEntry[key];
          delete updatedEntry[key];
        } else if (key === 'audited_cpt') {
          updatedEntry.audited_code = updatedEntry.audited_cpt;
          delete updatedEntry.audited_cpt;
          if(updatedEntry.audited_code && updatedEntry.audited_code.split('*')[1]){
            additionalAttributes['audited_units'] = updatedEntry.audited_code.split('*')[1] || null;
            updatedEntry.audited_code = updatedEntry.audited_code.split('*')[0];
          }
        } else if (key === 'srvcs_no'){
          if(updatedEntry.srvcs_no && updatedEntry.srvcs_no.split('*')[1]){
            additionalAttributes['srvcs_units'] = updatedEntry.srvcs_no.split('*')[1] || null;
            updatedEntry.srvcs_no = updatedEntry.srvcs_no.split('*')[0];
          }
        }
      }

      if (Object.keys(additionalAttributes).length > 0) {
        updatedEntry.additional_attributes = additionalAttributes;
      }

      return updatedEntry;
    });

    return {
      ...sheet,
      data: addMissingKeysWithNull(payloadData),
    };
  });
}

function addMissingKeysWithNull(arr) {
  const dynamicKey = 'additional_attributes';

  const allKeys = new Set();
  const allNestedKeys = new Set();

  //  getting all keys
  arr?.forEach(obj => {
    Object.keys(obj).forEach(key => {
      allKeys.add(key);
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.keys(obj[key]).forEach(nestedKey => {
          allNestedKeys.add(nestedKey);
        });
      }
    });
  });

  const dynamicKeyValues = new Map();
  // getting addionalattributes
  arr?.forEach(obj => {
    if (typeof obj[dynamicKey] === 'object' && obj[dynamicKey] !== null) {
      dynamicKeyValues.set(obj[dynamicKey], true);
    }
  });

  const unionKeys = new Set();

  arr?.forEach(obj => {
    if (dynamicKey in obj) {
      Object.keys(obj[dynamicKey]).forEach(key => {
        unionKeys.add(key);
      });
    }
  });

  arr?.forEach(obj => {
    if (dynamicKey in obj) {
      const dynamicKeyValue = { ...obj[dynamicKey] };
      unionKeys.forEach(nestedKey => {
        if (typeof dynamicKeyValue[nestedKey] === 'undefined') {
          dynamicKeyValue[nestedKey] = null;
        }
      });
      obj[dynamicKey] = dynamicKeyValue;
    } else {
      const nullObj = {};
      unionKeys.forEach(nestedKey => {
        nullObj[nestedKey] = null;
      });
      obj[dynamicKey] = nullObj;
    }

    allKeys?.forEach(key => {
      if (!(key in obj) || obj[key] === null) {
        obj[key] = null;
      }
    });

    dynamicKeyValues?.forEach((value, key) => {
      if (value && key !== obj[dynamicKey]) {
        Object.keys(key).forEach(k => {
          if (typeof obj[dynamicKey][k] === 'undefined') {
            obj[dynamicKey][k] = key[k];
          }
        });
      }
    });
  });

  return arr;
}

/**
 *
 * @param {Array} sheet
 */
export const isEmptySheet = sheet => {
  return sheet.every(obj => Object.keys(obj).length === 1);
};
export const emptyDataPrefilledObject = currentUpload => {
  return {
    file_name: currentUpload?.upload_id,
    chart_id: currentUpload.id,
    row_id: 1,
    id: null,
    missing_info: null,
    encounter_no: null,
    rendering: { first_name: '', last_name: '', id: '' },
    enc_dt: null,
    billed_icd: null,
    audited_icd: null,
    provider_education: null,
    number_complexity: null,
    amount_complexity: null,
    risk_complications: null,
    srvcs_no: null,
    billed_modifier: null,
    provider_rvu: null,
    provider_dollar_value: null,
    response: null,
    agree: null,
    disagree: null,
    audited_cpt: null,
    audited_modifier: null,
    audited_rvu: null,
    audited_dollar_value: null,
    notes: null,
  };
};


export function validateUnits(code_with_units){
  //Check if units available in CPT code or not, if not or available with correct formet return true else false
  if(code_with_units.includes('*')){
    const units = code_with_units.split('*')[1];
    const hasSingleAsterisk = code_with_units.split('*').length === 2;
    const isValid = /^\d+$/.test(units);

      return (isValid && hasSingleAsterisk);
    }
    return true;
}

export function validateCPT(code, industryCodes){
  //It will remove modifier if auditor add modifier in CPT field.
  const cpt = code.split('-')[0];
  return Object.keys(industryCodes).includes(cpt);
}

export function handleAgreeDisagree(nextSheetData, changes){
  //For set icd agree and disagree value as per input
  let columnChangeName = changes[1]
  let autoChangeName = columnChangeName === 'icd_agree'? 'icd_disagree':'icd_agree'
  const row = {
    [columnChangeName]:
      changes[3] === ''
        ? null
        : changes[3]?.toLowerCase() === 'yes' ||
          changes[3]?.toLowerCase() === 'true'
        ? true
        : false,
    [autoChangeName]:
      changes[3] === ''
        ? null
        : changes[3]?.toLowerCase() === 'yes' ||
          changes[3]?.toLowerCase() === 'true'
        ? false
        : true,
  };
  return row;
}

export const validateColumnValue = (columnName, value) => {
  const patterns = {
    encounter_no: /^.{1,20}$/,
    icd: /^.{1,20}$/,
    modifier: /^.{1,20}$/,
    rvu: /^\d{1,10}(\.\d{1,4})?$/,
    dollar: /^\d{1,10}(\.\d{1,4})?$/,
    enc_dt: /^(?:\d{2}-\d{2}-\d{2}|\d{2}-\d{2}-\d{4})$/,
    // notes: /^.{0,800}$/,
  };

  const errorMessages = {
    encounter_no: `Invalid value for ${columnName}: Alphanumeric, max length 20`,
    icd: `Invalid value for ${columnName}: Alphanumeric, max length 20`,
    modifier: `Invalid value for ${columnName}: Alphanumeric, max length 20`,
    rvu: `Invalid value for ${columnName}: Numeric or float, max 10 digits, 4 decimals`,
    dollar: `Invalid value for ${columnName}: Numeric or float, max 10 digits, 4 decimals`,
    missing_info: `Invalid value for ${columnName}`,
    enc_dt: `Invalid value for ${columnName}: Use MM-DD-YY/YYYY format`,
    // notes: `Invalid value for ${columnName}: Alphanumeric, max length 800`,
  };
  
  if (columnName === 'encounter_no') {
    return {
      isValid: patterns.encounter_no.test(value),
      message: errorMessages.encounter_no,
    };
  }

  if (/(.*)_icd(.*)/.test(columnName)) {
    return {
      isValid: patterns.icd.test(value),
      message: errorMessages.icd,
    };
  }

  if (/(.*)_modifier(.*)/.test(columnName)) {
    return {
      isValid: patterns.modifier.test(value),
      message: errorMessages.modifier,
    };
  }

  if (/(.*)_dollar_value/.test(columnName)) {
    return {
      isValid: patterns.dollar.test(value),
      message: errorMessages.dollar,
    };
  }

  if (columnName === 'provider_rvu' || columnName === 'audited_rvu') {
    return {
      isValid: patterns.rvu.test(value),
      message: errorMessages.rvu,
    };
  }

  if (columnName === 'missing_info') {
    const isValid = value === true || value === null;
    return {
      isValid: isValid,
      message: isValid ? '' : errorMessages.missing_info,
    };
  }
  
  if (columnName === 'enc_dt') {
    try{
      if(value){
        const d = Date.parse(value);
        const formattedDate = format(new Date(d), 'MM-dd-yyyy')
        return {
          isValid: patterns.enc_dt.test(formattedDate),
          message: errorMessages.enc_dt,
        };
      }else{
        return {
          isValid: true,
          message: errorMessages.enc_dt,
        };
      }
    } catch{
      return {
        isValid: false,
        message: errorMessages.enc_dt,
      };
    }
  }

  return { isValid: true, message: '' };
};

export function getBaseKeyword(columnName, dynamicColumnKeywords) {
  for (let baseColumnName of dynamicColumnKeywords) {
    if (columnName.startsWith(baseColumnName)) {
      return baseColumnName;
    }
  }
  return columnName;
}

export function cptSanitize(code){
  //Code-modifier*units. and remove modifier
  let CPTwithModifier = code.split('*')[0];
  let units = '';
  let [cpt, ...modifiers]  = CPTwithModifier.split('-');
  const modifierString = modifiers.length > 0 ? modifiers.join(',').trim() : '';
  if (code.split('*')[1]){
    units = code.split('*')[1].trim();
    cpt = `${cpt}*${units}`
  }
  return {
    cpt: cpt.trim(),
    modifiers: modifierString,
  };
}

export function updateRVUand$Value(nextSheetData, rowIndex, industryCodes){
  //Calculate RVU and $Value every time if any CPT or Modifier field get updated.
  let provider_dollar_value = 0
  let provider_rvu = 0
  let audited_dollar_value = 0
  let audited_rvu = 0
  for (let key in nextSheetData[rowIndex]) {
    if (key.startsWith('srvcs_no') && nextSheetData[rowIndex][key]) {
      const code = nextSheetData[rowIndex][key].split('*')[0]
      const units = Number(nextSheetData[rowIndex][key].split('*')[1]) || 1;
      const modifier = nextSheetData[rowIndex][key.replace('srvcs_no', 'billed_modifier')] || ''
      let industryData = industryCodes[code]
      //$Value or RVU value only change for 'TC' and '26' modifier for other we can use default values.
      if(modifier.includes('TC')){
        industryData = industryCodes[`${code}-TC`];
      }else if(modifier.includes('26')){
        industryData = industryCodes[`${code}-26`];
      }
      
      if(industryData){
        provider_dollar_value = provider_dollar_value + (units*industryData['limiting_charge_amount'])
        provider_rvu = provider_rvu + (units*industryData['rvu_value'])
      }
    }else if(key.startsWith('audited_cpt') && nextSheetData[rowIndex][key]){
      if(nextSheetData[rowIndex].agree){
        audited_dollar_value = provider_dollar_value
        audited_rvu = provider_rvu
      }else{
        const code = nextSheetData[rowIndex][key].split('*')[0];
        const units = Number(nextSheetData[rowIndex][key].split('*')[1]) || 1;
        const modifier = nextSheetData[rowIndex][key.replace('audited_cpt', 'audited_modifier')] || ''
        let industryData = industryCodes[code]
        //$Value or RVU value only change for 'TC' and '26' modifier for other we can use default values.
        if(modifier.includes('TC')){
          industryData = industryCodes[`${code}-TC`];
        }else if(modifier.includes('26')){
          industryData = industryCodes[`${code}-26`];
        }
        if(industryData){
          audited_dollar_value = audited_dollar_value + (units*industryData['limiting_charge_amount'])
          audited_rvu = audited_rvu + (units*industryData['rvu_value'])
        }
      }
    }
  }
  nextSheetData[rowIndex]['provider_dollar_value'] = provider_dollar_value.toFixed(2);
  nextSheetData[rowIndex]['provider_rvu'] = provider_rvu.toFixed(2);
  nextSheetData[rowIndex]['audited_dollar_value'] = audited_dollar_value.toFixed(2);
  nextSheetData[rowIndex]['audited_rvu'] = audited_rvu.toFixed(2);

  return nextSheetData;
}
