export const BASE_URL = process.env.REACT_APP_BASE_URL;
// export const BASE_URL = 'https://devapi.codequick.com';

export const AUDITOR_LOGIN = BASE_URL + '/login';
export const USER_LOOKUP = BASE_URL + '/users/me';
export const FORGOT_PASSWORD = BASE_URL + '/forgot-password';
export const LOGOUT = BASE_URL + '/logout';
export const AUDIT_UPLOADS = BASE_URL + '/manager/charts';
export const RECENT_AUDITS = BASE_URL + '/auditsheet/audithours/recent_audits';

//AUDIT SHEET
export const AUDIT_SHEET = BASE_URL + '/auditsheet/:uploadId/';
export const MONITOR_HOUR = BASE_URL + '/auditsheet/audithours/:uploadId/';
export const AUDIT_UPLOAD = BASE_URL + '/manager/charts/:uploadId/';
export const AUDIT_SHEET_INDUSTRY = BASE_URL + '/auditsheet/industry/';
export const AUDIT_SHEET_ICD_CODES = BASE_URL + '/auditsheet/icd-code/';
export const UPDATE_ACTIVITY =
  BASE_URL + '/auditsheet/comment/updation_activity/';
export const AUDITSHEET_USER = BASE_URL + '/auditsheet/cqusers/';
export const GET_COMMENT = BASE_URL + '/auditsheet/comment/:uploadId';
export const GET_ALL_USER_API = BASE_URL + '/users';
export const GET_COMMENT_FLAG =
  BASE_URL + '/auditsheet/comment/:uploadId/cell_flag/';
export const POST_COMMENT = BASE_URL + '/auditsheet/comment/';
export const MANAGE_COMMENT = BASE_URL + '/auditsheet/comment/:commentId/';
export const AUDIT_SHEET_PROVIDER_DROPDOWN =
  BASE_URL + '/client/dashboard/:chartId/provider_dropdown/';

export const UPLOAD_AUDITED_CHART = BASE_URL + "/manager/charts/upload_audited_chart/";

// notifications
export const GET_NOTIFICATIONS = BASE_URL + '/notifications/';

export const CHANGE_NOTIFICATION_STATUS =
  BASE_URL + '/notifications/:notificationId/';

export const GET_FAQ = BASE_URL + '/faq/';
