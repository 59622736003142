export default class AttachWorkerBuilder extends Worker {
  constructor() {
    const workerScript = `
    self.onmessage = async function (e) {
      const { UPLOAD_AUDITED_CHART, token, formDataObject } = e.data;

      const formData = new FormData();
      formData.append("upload_audited_chart", formDataObject.upload_audited_chart);
      formData.append("chart_id", formDataObject.chart_id);
      
      if (!formData) {
        postMessage({ success: false, error: 'No file provided' });
        return;
      }

      try {
        // Perform the fetch operation
        const response = await fetch(UPLOAD_AUDITED_CHART, {
          method: 'PATCH',
          body: formData,
          headers: {
            Authorization: token,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to upload file');
        }

        const data = await response.json();

        // Send the result back to the main thread
        postMessage({ success: true, data });
      } catch (error) {
        // Send the error back to the main thread
        postMessage({ success: false, error: error.message });
      }
    };
    `;
    const blob = new Blob([workerScript], { type: 'application/javascript' });
    return new Worker(URL.createObjectURL(blob));
  }
}
